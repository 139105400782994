import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AUTHORITIES } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import React from 'react';
import { Translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

export interface ILeftMenuProps {
  authorities: string[];
  onClick;
}

export const LeftMenu = (props: ILeftMenuProps) => {
  const authorities = useAppSelector(state => state.authentication.account?.authorities);

  return (
    <div className="d-flex justify-content-start">
      {!hasAnyAuthority(authorities, [AUTHORITIES.ADMIN, AUTHORITIES.PATIENT_USER]) && (
        <NavLink tag={Link} to="/patient" onClick={props.onClick}>
          <Translate contentKey="global.menu.entities.patient" />
        </NavLink>
      )}
      {hasAnyAuthority(props.authorities, [AUTHORITIES.VIEW_CLINIC]) && (
        <NavLink tag={Link} to="/clinic" onClick={props.onClick}>
          <Translate contentKey="global.menu.entities.clinic" />
        </NavLink>
      )}

      {hasAnyAuthority(props.authorities, [AUTHORITIES.VIEW_MATCHING]) && (
        <NavLink tag={Link} to="/matching" onClick={props.onClick}>
          <Translate contentKey="global.menu.entities.matching" />
        </NavLink>
      )}
    </div>
  );
};
