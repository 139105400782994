    import React, { useEffect, useState } from 'react';
    import { Link, useNavigate, useParams } from 'react-router-dom';
    import { Button, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
    import { Translate, translate } from 'react-jhipster';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

    import { convertDateFromServer, convertDateTimeFromServer, displayDefaultDate } from 'app/shared/util/date-utils';
    import { cleanEntity } from 'app/shared/util/entity-utils';
    import { useAppDispatch, useAppSelector } from 'app/config/store';

    import { getUsers } from 'app/shared/reducers/user-management';
    import { getEntities as getClinics } from 'app/entities/clinic/clinic.reducer';
    import { Sex } from 'app/shared/model/enumerations/sex.model';
    import { getEntity, updateEntity, createEntity, reset } from './patient.reducer';
    import { hasAnyAuthority } from 'app/shared/auth/private-route';
    import { AUTHORITIES } from 'app/config/constants';

    export const PatientUpdate = () => {
      const dispatch = useAppDispatch();

      const navigate = useNavigate();

      const { patientId } = useParams<'patientId'>();
      const isNew = patientId === undefined;

      const patientEntity = useAppSelector(state => state.patient.entity);
      const clinics = useAppSelector(state => state.clinic.entities);
      const loading = useAppSelector(state => state.patient.loading);
      const updating = useAppSelector(state => state.patient.updating);
      const updateSuccess = useAppSelector(state => state.patient.updateSuccess);
      const authorities = useAppSelector(state => state.authentication.account.authorities);

      const sexValues = Object.keys(Sex);

      const handleClose = () => {
        navigate(`/patient/${patientEntity.id}`);
      };

      useEffect(() => {
        if (isNew) {
          dispatch(reset());
        } else {
          dispatch(getEntity(patientId));
        }

        dispatch(getUsers({}));
        dispatch(getClinics({}));
      }, []);

      useEffect(() => {
        if (updateSuccess) {
            handleClose();
        }
      }, [updateSuccess]);

      // eslint-disable-next-line complexity
    const saveEntity = event => {
      event.preventDefault();
      const form = event.currentTarget;  // Use currentTarget to refer to the form
      const formData = new FormData(form); // Creates FormData object from form element

      // Extract photoFile from FormData and remove it from formData
      const photoFile = formData.get('photoFile');
      formData.delete('photoFile');  // This ensures only patient-specific data remains in formData

      // Prepare the rest of the data as a JSON object
      const patientData = defaultValues;
      formData.forEach((value, key) => {
        if (key === 'birthdate' && value) {
          // Convert date to ISO 8601 format with time set to midnight UTC
          const date = new Date(value.toString());
          const isoDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
          patientData[key] = isoDate;
        } else if (key === 'clinic' && value) {
          patientData['clinics'] = [{id: value}];
        } else {
          patientData[key] = value;
        }
      });
      const patient = cleanEntity(patientData);

      const bundleFormData = new FormData();
      bundleFormData.set('patient', new Blob([JSON.stringify(patient)], {type: "application/json"}));
      bundleFormData.set('id', patientId);
      if (photoFile) {
        bundleFormData.set('photoFile', photoFile);
      }

      // Dispatch appropriate action
      if (isNew) {
          dispatch(createEntity(bundleFormData));
      } else {
        dispatch(updateEntity(bundleFormData));
      }
    };

    const defaultValues =
      isNew
        ? {
            birthdate: displayDefaultDate(),
          }
        : {
            sex: 'M',
            ...patientEntity,
            clinic: patientEntity.clinics && patientEntity.clinics.length > 0 ? patientEntity.clinics[0].id : null,
            birthdate: convertDateFromServer(patientEntity.birthdate),
          };

            return (
              <div>
                <Row className="justify-content-center">
                  <Col md="8">
                    <h2 id="dfaiApp.patient.home.createOrEditLabel" data-cy="PatientCreateUpdateHeading">
                      <Translate contentKey="dfaiApp.patient.home.createOrEditLabel">Create or edit a Patient</Translate>
                    </h2>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <Form onSubmit={saveEntity} className="justify-content-center">
                      {!isNew ? (
                        <div>
                          <Input
                            name="id"
                            required
                            hidden
                            disabled={true}
                            id="patient-id"
                            validate={{ required: true }}
                            defaultValue={defaultValues.id}
                          />
                        </div>
                      ) : null}
                      <Row className="justify-content-center">
                        <Col md="8">
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for='patient-firstName'>{translate('dfaiApp.patient.firstName')}</Label>
                                <Input
                                  id="patient-firstName"
                                  name="firstName"
                                  data-cy="firstName"
                                  type="text"
                                  defaultValue={defaultValues.firstName}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for='patient-lastName'>{translate('dfaiApp.patient.lastName')}</Label>
                                <Input
                                  id="patient-lastName"
                                  name="lastName"
                                  data-cy="lastName"
                                  type="text"
                                  defaultValue={defaultValues.lastName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                            <FormGroup>
                              <Label for='patient-birthdate'>{translate('dfaiApp.patient.birthdate')}</Label>
                              <Input
                                id="patient-birthdate"
                                name="birthdate"
                                data-cy="birthdate"
                                type="date"
                                placeholder="DD-MM-YYYY"
                                defaultValue={defaultValues.birthdate}
                              />
                              <Label for='patient-address'>{translate('dfaiApp.patient.address')}</Label>
                              <Input
                                id="patient-address"
                                name="address"
                                data-cy="address"
                                type="text"
                                defaultValue={defaultValues.address}
                              />
                            </FormGroup>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for='patient-zipCode'>{translate('dfaiApp.patient.zipCode')}</Label>
                                <Input
                                id="patient-zipCode"
                                name="zipCode"
                                data-cy="zipCode"
                                type="text"
                                defaultValue={defaultValues.zipCode}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for='patient-city'>{translate('dfaiApp.patient.city')}</Label>
                                <Input id="patient-city" name="city" data-cy="city" type="text" defaultValue={defaultValues.city}/>
                              </FormGroup>
                            </Col>
                          </Row>
                            <FormGroup>
                              <Label for='patient-country'>{translate('dfaiApp.patient.country')}</Label>
                              <Input
                                id="patient-country"
                                name="country"
                                data-cy="country"
                                type="text"
                                defaultValue={defaultValues.country}
                              />
                            </FormGroup>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for='patient-email'>{translate('dfaiApp.patient.email')}</Label>
                                <Input id="patient-email" name="email" data-cy="email" type="email" required defaultValue={defaultValues.email}/>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for='patient-phoneNumber'>{translate('dfaiApp.patient.phoneNumber')}</Label>
                                <Input
                                  id="patient-phoneNumber"
                                  name="phoneNumber"
                                  data-cy="phoneNumber"
                                  type="text"
                                  defaultValue={defaultValues.phoneNumber}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Label for='patient-sex'>{translate('dfaiApp.patient.sex')}</Label>
                            <Input id="patient-sex" name="sex" data-cy="sex" type="select" defaultValue={defaultValues.sex}>
                              {sexValues.map(sex => (
                                <option value={sex} key={sex}>
                                  {translate('dfaiApp.Sex.' + sex)}
                                </option>
                              ))}
                            </Input>
                            <Label for='patient-socialNumber'>{translate('dfaiApp.patient.socialNumber')}</Label>
                            <Input
                              id="patient-socialNumber"
                              name="socialNumber"
                              data-cy="socialNumber"
                              required
                              readOnly={!isNew}
                              type="text"
                              defaultValue={defaultValues.socialNumber}
                            />
                            <Label for='patient-mutuelleName'>{translate('dfaiApp.patient.mutuelleName')}</Label>
                            <Input
                              id="patient-mutuelleName"
                              name="mutuelleName"
                              data-cy="mutuelleName"
                              type="text"
                              defaultValue={defaultValues.mutuelleName}
                            />
                            <Label for='patient-mutuelleNumber'>{translate('dfaiApp.patient.mutuelleNumber')}</Label>
                            <Input
                              id="patient-mutuelleNumber"
                              name="mutuelleNumber"
                              data-cy="mutuelleNumber"
                              type="text"
                              defaultValue={defaultValues.mutuelleNumber}
                            />
                            <Label for='patient-mutuelleMail'>{translate('dfaiApp.patient.mutuelleMail')}</Label>
                            <Input
                              id="patient-mutuelleMail"
                              name="mutuelleMail"
                              data-cy="mutuelleMail"
                              type="text"
                              defaultValue={defaultValues.mutuelleMail}
                            />
                            <Label for='patient-clinic'>{translate('dfaiApp.patient.clinic')}</Label>
                            {clinics && <Input
                              id="patient-clinic"
                              name="clinic"
                              data-cy="clinic"
                              type="select"
                              required
                              defaultValue={defaultValues.clinic}
                            >
                              {clinics.map(clinic => (
                                <option value={clinic.id} key={clinic.id}>
                                  {clinic.name}
                                </option>
                              ))}
                            </Input>}
                            <Label for='photoFile'>{translate('dfaiApp.patient.photo')}</Label>
                            <Input
                              id="patient-photoFile"
                              name="photoFile"
                              accept="image/*"
                              data-cy="photoFile"
                              type="file"
                              />
                          </FormGroup>
                          <div className="d-flex justify-content-end">
                            <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to={patientEntity?.id ? `/patient/${patientEntity.id}` : '/patient'} replace color="info">
                              <FontAwesomeIcon icon="arrow-left" />
                              &nbsp;
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.back">Back</Translate>
                              </span>
                            </Button>
                            &nbsp;
                            { isNew || hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_PATIENT])}
                            <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                              <FontAwesomeIcon icon="save" />
                              &nbsp;
                              <Translate contentKey="entity.action.save">Save</Translate>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Row>
              </div>
            );
          };

    export default PatientUpdate;
