import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, getPatientPhoto } from './patient.reducer';
import Consultation from './consultation/consultation';
import PatientOverview from './patient-overview';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { convertDateFromServer } from 'app/shared/util/date-utils';

export const PatientDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { patientId } = useParams<'patientId'>();
  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = tabId => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    dispatch(getEntity(patientId));
  }, []);

  const patientEntity = useAppSelector(state => state.patient.entity);
  const [photoUrl, setPhotoUrl] = useState('');

  useEffect(() => {
    const fetchPhoto = async () => {
      if (patientEntity && patientEntity.photoFileName) {
        try {
          const response = await dispatch(getPatientPhoto(patientEntity.id)).unwrap();
          const objectURL = URL.createObjectURL(response);
          setPhotoUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load photo:', error);
        }
      }
    };

    fetchPhoto();
  }, [patientEntity, dispatch]);

  return (
    <Row>
      <Col md="10">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-inline-flex align-items-center">
            {photoUrl && 
              <span className="p-1">
                <img src={photoUrl} alt="patient" className="img-fluid" style={{ minWidth: '3rem', maxWidth: '5rem', maxHeight: '5rem' }}/>
              </span>}
            <h2  className="p-1" data-cy="patientDetailsHeading">
              {`${translate("dfaiApp.patient.detail.title")}: ${patientEntity.firstName} ${patientEntity.lastName}`}
            </h2>
            <span className="p-1">
              {patientEntity.birthdate && ` ${convertDateFromServer(patientEntity.birthdate)}`}
              {` ${patientEntity.socialNumber}`}
            </span>
          </div>
          <div>
            <Button tag={Link} to="/patient" replace color="info" size="lg" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/patient/${patientEntity.id}/edit`} size="lg" replace color="info">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </div>
        </div>
      </Col>
      <Col md="10">
        <Nav tabs>
          <NavItem>
            <NavLink className={`tab ${activeTab === '1' ? 'active' : ''}`} onClick={() => toggleTab('1')}>
            <Translate contentKey="dfaiApp.consultation.home.overview">Overview</Translate>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={`tab ${activeTab === '2' ? 'active' : ''}`} onClick={() => toggleTab('2')}>
            <Translate contentKey="dfaiApp.consultation.home.title">Consultations</Translate>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="tab" onClick={() => navigate(`/patient/${patientId}/consultation/new`)} id="jh-create-entity">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="dfaiApp.consultation.home.createLabel">Create new Consultation</Translate>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="tab" onClick={() => navigate(`/patient/${patientId}/consultation/report`)} id="jh-see-report">
              <FontAwesomeIcon icon={faFileExport} />
              &nbsp;
              <Translate contentKey="dfaiApp.consultation.report.show">Report</Translate>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <PatientOverview/>
          </TabPane>
          <TabPane tabId="2">
            <Consultation/>
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default PatientDetail;
