import { IImageDiagnostic } from 'app/shared/model/image-diagnostic.model';
import { IConsultation } from 'app/shared/model/consultation.model';
import { IPathologyPrediction } from './pathology-prediction.model';
import { IPredictionImageFilter } from './prediction-image-filter.model';

export interface IImage {
  id?: string;
  storedName?: string | null;
  type?: string | null;
  imageDiagnostic?: IImageDiagnostic | null;
  consultation?: IConsultation | null;
  pathologyPredictions?: IPathologyPrediction[] | null;
  predictionImageFilters?: IPredictionImageFilter[] | null;
  dpi?: number;
  x?: string;
  y?: string;
  zoom?: string;
  width?: string;
  height?: string;
  brightness?: string;
  contrast?: string;
  annotation?: string;
  validated?: boolean;
  createdAt?: Date;
}

export const defaultValue: Readonly<IImage> = {};
