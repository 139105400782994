import React, { useState, useEffect, useRef } from 'react';
import { Progress } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAppSelector } from 'app/config/store';

const ProgressBar = ({ loading, progress, total }) => {
  const [value, setValue] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const pendingAudioRef = useRef(null);
  const endsAudioRef = useRef(null);
  const hasStarted = useRef(false);

  useEffect(() => {
    let progressInterval;

    if (loading) {
      setValue(0);
      hasStarted.current = true;
      setShowProgress(true);
      pendingAudioRef.current.loop = true;
      pendingAudioRef.current.play();
      /*progressInterval = setInterval(() => {
        setValue(prevValue => {
          const newValue = prevValue + (0.02 * Math.pow(1 - Math.sqrt(prevValue / 100), 2)) * 100;
          return newValue >= 100 ? 100 : newValue;
        });
      }, 16);*/
    } else if (hasStarted.current) {
      endsAudioRef.current.play();
      clearInterval(progressInterval);
      pendingAudioRef.current.pause();
      pendingAudioRef.current.currentTime = 0; // Reset audio to start
      setValue(100); // Immediately complete the progress bar
      hasStarted.current = false;
    } else {
    }
  }, [loading]);

  useEffect(() => {
    let updateInterval;

    if (showProgress && value < ((progress/total)*100)) {
      updateInterval = setInterval(() => {
        setValue(prevValue => {
          const increment = 0.5;
          const newValue = prevValue + increment;
          return newValue >= (progress/total)*100 ? (progress/total)*100 : newValue;
        });
    }, 16);
  }

    return () => clearInterval(updateInterval);
  }, [progress, showProgress, value]);

  // Hide progress bar after ending song finishes
  const handleEndsAudioEnded = () => {
    setShowProgress(false);
  };

  return (
    <div>
      {showProgress && total && <Progress value={value}>{progress} / {total}</Progress>}
      <audio ref={pendingAudioRef} src="content/audio/prediction-pending.mp3" />
      <audio ref={endsAudioRef} src="content/audio/prediction-end.mp3" onEnded={handleEndsAudioEnded} />
    </div>
  );
};

export default ProgressBar;
