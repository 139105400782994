import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { getLoggedPatientInfo } from 'app/shared/reducers/authentication';
import { Card, CardHeader, CardTitle, CardFooter, CardDescription, CardContent } from '../components/ui/Card';
import { CalendarIcon, LocateIcon, UsersIcon, FileTextIcon, FileIcon } from '../components/icons';

interface Professional {
  firstName: string;
  lastName: string;
}

interface Consultation {
  id: string;
  date: string;
  practitionerSnapshot: string;
  practitioner: Professional;
}

interface Patient {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  socialNumber: string;
  consultations: Consultation[];
}

const PatientConsultationsPage: React.FC = () => {
  const { socialNumber } = useParams<{ socialNumber: string }>();
  const [error, setError] = useState<string | null>(null);
  
  const dispatch = useAppDispatch();  // Utilisation de useAppDispatch pour obtenir dispatch

  const patientInfo = useAppSelector(state => {
    const loggedPatientInfo = state.authentication.account.loggedPatientInfo as Patient[] | undefined;
    return loggedPatientInfo?.find(patient => patient.socialNumber === socialNumber);
  });
  

  useEffect(() => {
    if (!patientInfo && socialNumber) {
      dispatch(getLoggedPatientInfo(socialNumber));  // Utilisation de dispatch avec getLoggedPatientInfo
    } else if (!socialNumber) {
      setError("Numéro de sécurité sociale manquant.");
    }
  }, [socialNumber, patientInfo, dispatch]);

  if (!patientInfo) {
    return (
      <div className="p-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Erreur : </strong>
          <span>{error || "Chargement des informations du patient..."}</span>
        </div>
      </div>
    );
  }

  const consultations = patientInfo.consultations || [];
  const sortedConsultations = [...consultations].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-muted flex flex-col items-center">
      <div className="container mx-auto space-y-12 px-4">
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
            Consultations de {patientInfo.firstName} {patientInfo.lastName}
          </h2>
          <p className="text-muted-foreground md:text-xl/relaxed">
            Consultez l'historique des consultations médicales.
          </p>
        </div>

        {consultations.length > 0 ? (
          <div className="grid gap-4">
            {sortedConsultations.map((consultation, index) => (
              <Card key={consultation.id}>
                <CardHeader>
                  <CardTitle>
                    Consultation du {new Date(consultation.date).toLocaleDateString('fr-FR')}
                  </CardTitle>
                  <CardDescription>
                    <div className="flex items-center gap-2">
                      <CalendarIcon className="h-4 w-4 text-muted-foreground" />
                      <span>{new Date(consultation.date).toLocaleDateString('fr-FR')}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <UsersIcon className="h-4 w-4 text-muted-foreground" />
                      <span>{consultation.practitionerSnapshot}</span>
                    </div>
                  </CardDescription>
                </CardHeader>

                <CardContent>
                  {consultation.practitioner && (
                    <div className="flex items-center gap-2">
                      <FileTextIcon className="h-4 w-4 text-muted-foreground" />
                      <span>Praticien : {consultation.practitioner.firstName} {consultation.practitioner.lastName}</span>
                    </div>
                  )}
                </CardContent>

                <CardFooter>
                  <Link to={`/patient-app/appointments/${patientInfo.id}/${consultation.id}`} className="text-sm font-medium text-primary-tailwind">
                Voir en détails
                  </Link>
                </CardFooter>
              </Card>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-lg">
            <p className="text-gray-600">Aucune consultation trouvée.</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default PatientConsultationsPage;