import axios from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { serializeAxiosError } from './reducer.utils';
import { IProfessional } from '../model/professional.model';
import { IUser } from '../model/user.model';
import { IPatient } from '../model/patient.model';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};

const logged = 'api/logged-professional';
const loggedUser = 'api/logged-user';

interface Patient {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  socialNumber?: string;
  // Autres champs...
}

interface AccountState {
  id?: string;
  activated?: boolean;
  // Autres champs...
  loggedPatientInfo?: Patient[];
}
export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

export const logoutServer = createAsyncThunk('authentication/logout', async () => axios.post<any>('api/logout', {}), {
  serializeError: serializeAxiosError,
});

export const logout: () => AppThunk = () => async dispatch => {
  await dispatch(logoutServer());
  // fetch new csrf token
  dispatch(getSession());
};

export const getLoggedProfessional = createAsyncThunk(
  'professional/fetch_logged_entity',
  async () => {
    const requestUrl = `${logged}`;
    return axios.get<IProfessional>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getAggregatedPatientInfo = createAsyncThunk(
  'user/fetch_aggregated_patient_info',
  async (keycloakId: string) => {
    const requestUrl = `/api/patients/patient-info/${keycloakId}`;
    return axios.get<IPatient[]>(requestUrl); // Notez que le retour est une liste de patients
  },
  { serializeError: serializeAxiosError },
);

export const getLoggedPatientInfo = createAsyncThunk(
  'user/fetch_patient_info',
  async (accountId:string) => {
    const requestUrl = `/api/patients/patient-info/${accountId}`;
    return axios.get<IPatient>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getLoggedUser = createAsyncThunk(
  'user/fetch_logged_entity',
  async (accountId) => {
    const requestUrl = `/api/patients/patient-info/${accountId}`;
    return axios.get<IPatient>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const clearAuthentication = messageKey => dispatch => {
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        account: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(getAggregatedPatientInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.account.loggedPatientInfo = action.payload.data; // Met à jour avec les données agrégées
      })
      .addCase(getLoggedProfessional.rejected, (state, action) => {
        state.loading = false;
        state.account.loggedProfessional = null;
      })
      .addCase(getLoggedProfessional.fulfilled, (state, action) => {
        state.loading = false;
        state.account.loggedProfessional = action.payload.data;
      })
      .addCase(getLoggedPatientInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.account.loggedPatientInfo = action.payload.data;
      })
      .addCase(getLoggedUser.rejected, (state, action) => {
        state.loading = false;
        state.account.loggedUser = null;
      })
      .addCase(getLoggedUser.fulfilled, (state, action) => {
        state.loading = false;
        state.account.loggedUser = action.payload.data;
      })
      .addCase(logoutServer.fulfilled, (state, action) => ({
        ...initialState,
        logoutUrl: action.payload.data.logoutUrl,
      }))
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { authError, clearAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
