import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getConsultationsOverview as getOverview, getEntity as getConsultation, updateDentalStatus } from './consultation/consultation.reducer';
import { getEntity as getPatient } from './patient.reducer';
import ImageViewer from './consultation/image/image-viewer';
import { IImage } from 'app/shared/model/image.model';
import { Translate, translate } from 'react-jhipster';
import { convertDateFromServer } from 'app/shared/util/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import DentalChart from 'app/shared/component/dental-chart/dental-chart';
import { isMobileOnly } from 'react-device-detect';

export const PatientOverview = () => {
  const dispatch = useAppDispatch();

  const { patientId, consultationId } = useParams();
  const sections = ['18','17','16','15 14','13 12','11 21','22 23','24 25','26','27','28',
    '48','47','46','45 44','43 42','41 31','32 33','34 35','36','37','38'];
  const [xRays, setXRays] = useState(sections.reduce((acc, section) => ({ ...acc, [section]: null }), {}));
  const [panoramic, setPanoramic] = useState(null);
  const [photos, setPhotos] = useState([]);
  const consultation = useAppSelector(state => state.consultation.entity);
  const patient = useAppSelector(state => state.patient.entity);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const modalRef = useRef(null);
  const panoramicContainer = useRef(null);
  const photoContainers = useRef(new Map());
  const radioContainers = useRef(new Map());
  const [detailedImage, setDetailedImage] = useState(null);
  const [xraysSize, setXraysSize] = useState(0);

  const upperTeeth = ["18", "17", "16", "15", "14", "13", "12", "11", "21", "22", "23", "24", "25", "26", "27", "28"];
  const lowerTeeth = ["48", "47", "46", "45", "44", "43", "42", "41", "31", "32", "33", "34", "35", "36", "37", "38"];

  const [missingTeeth, setMissingTeeth] = useState<string[]>([]);
  const [implants, setImplants] = useState<string[]>([]);
  const [crowns, setCrowns] = useState<string[]>([]);
  const [radioAffectationEnded, setRadioAffectationEnded] = useState(false);

  useEffect(() => {
    if (patientId) {
      dispatch(getPatient(patientId));
    }
    if (consultationId) {
      dispatch(getConsultation({ patientId, consultationId }));
    } else {
      dispatch(getOverview(patientId));
    }
  }, [consultationId, patientId, dispatch]);

  useEffect(() => {
    setMissingTeeth(patient.missingTeeth ? patient.missingTeeth : []);
    setImplants(patient.implants ? patient.implants : []);
    setCrowns(patient.crowns ? patient.crowns : []);
  }, [patient]);

  useEffect(() => {
    if (consultation) {
      if (consultation?.images) {
        setRadioAffectationEnded(false);
        const newXRays = sections.reduce((acc, section) => ({ ...acc, [section]: null }), {});
        const newPhotos = [];
        let newPanoramic = null;
        let xrayCount = 0;
        consultation.images.forEach(image => {
          if (image.type === 'panoramic') {
            newPanoramic = image;
          } else if (image.type === 'xray') {
            xrayCount = xrayCount + 1;
            const section = image.section;
            newXRays[section] = image;
            radioContainers.current.set(image.id, createRef());
          } else if (image.type === 'photo') {
            newPhotos.push(image);
            photoContainers.current.set(image.id, createRef());
          }
        });
        setXraysSize(xrayCount);
        setXRays(newXRays);
        setPhotos(newPhotos);
        setPanoramic(newPanoramic);
        setRadioAffectationEnded(true);
      }
      // Set dental status if exists
      if (consultation.dentalStatus) {
        const status = JSON.parse(consultation.dentalStatus);
        setMissingTeeth(status.missingTeeth || []);
        setImplants(status.implants || []);
        setCrowns(status.crowns || []);
      }
    }
  }, [consultation]);

  const handleSaveDentalStatus = async () => {
    const dentalStatus = {
      missingTeeth,
      implants,
      crowns,
    };

    const response = await dispatch(updateDentalStatus({ patientId, dentalStatus }));

    if (response.meta.requestStatus === 'fulfilled') {
      // Fetch the updated overview
      dispatch(getPatient(patientId));
    }
  };

  return (
    <div>
      {patient && 
        <div className="d-flex-column justify-content-center overflow-auto">
          <DentalChart 
          upperTeeth={upperTeeth} 
          lowerTeeth={lowerTeeth} 
          missingTeeth={missingTeeth}
          implants={implants}
          crowns={crowns}
          setMissingTeeth={setMissingTeeth}
          setImplants={setImplants}
          setCrowns={setCrowns}
        />
        <div className="d-flex justify-content-center">
          <Button onClick={handleSaveDentalStatus} color="primary">{translate("dfaiApp.patient.saveDentalStatus")}</Button> {/* Bouton pour sauvegarder le statut dentaire */}
        </div>
      </div>}
      <div style={{ marginTop: '1rem', marginBottom: '2rem', borderTop: '2px solid #000000' }} /> {/* Diviseur personnalisé ajouté ici */}
      <Row>
        <h3>{translate("dfaiApp.consultation.home.panoramic")}</h3>
        {panoramic ?
          <div className="mw-50" 
            style={{height:'25vh', minHeight:'100px'}}
            ref={panoramicContainer}
            onClick={() => setDetailedImage(panoramic)}>
            <ImageViewer
              imageEntity={panoramic}
              patientId={patientId}
              pathologyPredictions={panoramic.pathologyPredictions}
              containerRef={panoramicContainer}
            />
          </div>
          : <Translate contentKey="dfaiApp.consultation.noPanoramic">No panoramic available</Translate>}
      </Row>
      <Row>
        <h3 style={{ marginTop: '2rem'}}>{translate("dfaiApp.consultation.home.xrays")}</h3>
      </Row>
      {xraysSize > 0 ? 
        <React.Fragment>
          {isMobileOnly && radioAffectationEnded &&
            <div className="d-flex flex-wrap m-1" style={{height:`auto`, minHeight:'100px'}}>
              {sections.map((section, colIndex) => {
                const typedImage = xRays[section] as IImage;
                if (typedImage) {
                  return (
                  <div key={section} className="p-0" style={{height:`30vh`, width:`20vh`}}>
                    <div className="h-75 w-100"
                      ref={radioContainers.current.get(typedImage.id)}
                      onClick={() => setDetailedImage(typedImage)}>
                      { typedImage && radioContainers?.current.get(typedImage.id) &&
                        <ImageViewer
                          imageEntity={typedImage}
                          pathologyPredictions={typedImage.pathologyPredictions}
                          patientId={patientId}
                          containerRef={radioContainers.current.get(typedImage.id)}
                          scaleOn="height"
                        />
                      }
                    </div>
                    <div className="h-25 text-sm text-center">{section}</div>
                  </div>
                  )
                }
              })}
            </div>}
          {!isMobileOnly && Array.from({ length: Math.ceil(Object.entries(xRays).length / 11) }, (_, rowIndex) => (
            <Row className="justify-content-between" key={rowIndex} style={{height:'15vh', minHeight:'100px'}}>
              {sections.slice(rowIndex * 11, rowIndex * 11 + 11).map((section, colIndex) => {
                const typedImage = xRays[section] as IImage;
                return (
                  <Col key={section} md="1" className="p-0 h-100">
                    <div className="h-75 w-100"
                      ref={radioContainers.current.get(typedImage?.id)}
                      onClick={() => setDetailedImage(typedImage)}>
                      { typedImage && radioContainers?.current.get(typedImage.id) &&
                        <ImageViewer
                          imageEntity={typedImage}
                          pathologyPredictions={typedImage.pathologyPredictions}
                          patientId={patientId}
                          containerRef={radioContainers.current.get(typedImage?.id)}
                        />
                      }
                    </div>
                    <div className="h-25 text-sm text-center">{section}</div>
                  </Col>
                )
              })}
            </Row>
          ))}
        </React.Fragment>
      : <Translate contentKey="dfaiApp.consultation.noXray">No xray available</Translate>
      }
      <Row>
        <h3>{translate("dfaiApp.consultation.home.photos")}</h3>
      </Row>
      <div className="d-flex flex-wrap m-1" style={{height:`auto`, minHeight:'100px'}}>
        { photos?.map(image => {
            console.log("trying to render photo");
            const typedImage = image as IImage;
            return (
              <Col md="2" className="m-1" style={{height:"15vh", width:`${isMobileOnly ? "20vw" : "15vw"}`}} onClick={() => setDetailedImage(typedImage)} key={typedImage.id}>
                { typedImage && 
                  <div className="h-100 w-100" ref={photoContainers.current.get(typedImage.id)}>
                    <ImageViewer
                      imageEntity={typedImage}
                      pathologyPredictions={typedImage.pathologyPredictions}
                      patientId={patientId}
                      containerRef={photoContainers.current.get(typedImage.id)}
                    />
                  </div>
                }
              </Col>
            )
          })}
      </div>
      
      { detailedImage && <Modal centered className="h-75 max75vh" contentClassName="h-100 w-100" size="lg" isOpen={true} toggle={() => setDetailedImage(null)} >
        <ModalHeader className="h5w100">
          <div className="d-inline-flex justify-content-between w-100">
            <h3>Details</h3>
            { hasAnyAuthority(authorities, [AUTHORITIES.MANAGE_CONSULTATION_PHOTO]) && <div className="px-2">
              <Button
                size="lg"
                tag={Link}
                to={`/patient/${patientId}/consultation/${consultationId || detailedImage.consultation.id}/image/${detailedImage.id}/edit`}
                color="info"
                data-cy="entityEditButton"
              >
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
            </div>}
          </div>
        </ModalHeader>
        <ModalBody className="h-100 w-100 d-flex justify-content-center">
          <div ref={modalRef} className="h-100 w-100 position-relative">
            <ImageViewer
              imageEntity={detailedImage}
              pathologyPredictions={detailedImage.pathologyPredictions}
              patientId={patientId}
              containerRef={modalRef}
            />
          </div>
        </ModalBody>
      </Modal>}
    </div>
  );
};

export default PatientOverview;
