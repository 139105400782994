// PatientSelectionPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../components/ui/Card';

interface Patient {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  socialNumber: string;
}

const PatientSelectionPage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const patients = useAppSelector(state => state.authentication.account.loggedPatientInfo) as Patient[];

  const handleSelectPatient = (socialNumber: string) => {
    navigate(`/patient-app/${socialNumber}/consultations`);
  };

  if (error) {
    return (
      <div className="p-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Erreur : </strong>
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-muted flex flex-col items-center">
      <div className="container mx-auto space-y-12 px-4">
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Sélectionnez un patient</h2>
          <p className="text-muted-foreground md:text-lg">Choisissez le profil du patient pour voir les consultations.</p>
        </div>

        {patients && patients.length > 0 ? (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {patients.map(patient => (
              <div key={patient.id} className="hover:shadow-md transition-shadow">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      {patient.firstName} {patient.lastName}
                    </CardTitle>
                    <CardContent>
                      <p className="text-sm text-muted-foreground">Email : {patient.email}</p>
                      <p className="text-sm text-muted-foreground">
                        Numéro de sécurité sociale : {patient.socialNumber}
                      </p>
                    </CardContent>
                  </CardHeader>
                  <CardFooter>
                    <button
                      onClick={() => handleSelectPatient(patient.socialNumber)}
                      className="text-primary-tailwind font-medium hover:underline"
                    >
                      Accéder au profil
                    </button>
                  </CardFooter>
                </Card>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600">Aucun patient trouvé.</p>
        )}
      </div>
    </section>
  );
};

export default PatientSelectionPage;
