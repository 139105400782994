// Utility to convert Base64 back to a Blob
export const base64ToBlob = (base64, mimeType) => {
    const binary = atob(base64.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeType });
  };

// Utility to convert a Blob to Base64
const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string); // Explicitly assert that result is a string
        } else {
          reject(new Error("Failed to convert Blob to Base64"));
        }
      };
      reader.onerror = () => reject(new Error("Error reading Blob"));
      reader.readAsDataURL(blob);
    });
  };

// Fonction utilitaire pour accéder au cache (sessionStorage)
export const getImageFromCache = (key) => {
  const cachedData = sessionStorage.getItem(key);
  if (!cachedData) return null;
  const mimeType = cachedData.split(';')[0].split(':')[1];
  return base64ToBlob(cachedData, mimeType);
};

export const setImageInCache = async (key, blob) => {
    const base64 = await blobToBase64(blob);
    sessionStorage.setItem(key, base64);
  };