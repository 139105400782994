import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PatientOverview from '../patient-overview';
import { faFileExport, faRobot } from '@fortawesome/free-solid-svg-icons';
import QuoteManager from './quotes/quote-manager';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { convertDateFromServer } from 'app/shared/util/date-utils';
import { isMobile } from 'react-device-detect';

export const ConsultationDetail = () => {
  const { patientId, consultationId } = useParams();
  const authorities = useAppSelector(state => state.authentication.account?.authorities);
  const patientEntity = useAppSelector(state => state.patient.entity);

  return (
    <Row>
      <Col md="9">
        <Row>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="d-inline-flex align-items-center">
              <h2 className="p-1" data-cy="imageDetailsHeading">
                {`${translate("dfaiApp.consultation.detail.title")} ${patientEntity.firstName} ${patientEntity.lastName}`}
              </h2>
              <span className="p-1">
                {patientEntity.birthdate && ` ${convertDateFromServer(patientEntity.birthdate)}`}
                {` ${patientEntity.socialNumber}`}
              </span>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <Button tag={Link} to={`/patient/${patientId}`} size={isMobile ? "sm" : "lg"} replace color="info" data-cy="entityDetailsBackButton">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              { hasAnyAuthority(authorities, [AUTHORITIES.MANAGE_CONSULTATION_PHOTO]) && <div className="mx-2">
                <Button
                  tag={Link}
                  to={`/patient/${patientId}/consultation/${consultationId}/edit`}
                  color="info"
                  size={isMobile ? "sm" : "lg"}
                  data-cy="entityEditButton"
                >
                  <FontAwesomeIcon icon={faRobot} />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.predictions">Predictions</Translate>
                  </span>
                </Button>
              </div>}
              <div className="mx-2">
                <Button tag={Link} to={`/patient/${patientId}/consultation/${consultationId}/report`} size={isMobile ? "sm" : "lg"} replace color="info" data-cy="entityDetailsReportButton">
                <FontAwesomeIcon icon={faFileExport} />
                &nbsp;
                <Translate contentKey="dfaiApp.consultation.report.show">Report</Translate>
              </Button>
              </div>
            </div>
          </div>
        </Row>
        <PatientOverview/>
      </Col>
      <Col md="3">
        <QuoteManager/>
      </Col>
    </Row>
  );
};

export default ConsultationDetail;
