import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Label, Input, FormGroup, Form } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getProfessionals } from 'app/entities/professional/professional.reducer';
import { getEntity, updateEntity, createEntity, reset, uploadClinicPhoto } from './clinic.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const ClinicUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clinicId } = useParams<'clinicId'>();
  const isNew = clinicId === undefined;

  const clinicEntity = useAppSelector(state => state.clinic.entity);
  const loading = useAppSelector(state => state.clinic.loading);
  const updating = useAppSelector(state => state.clinic.updating);
  const authorities = useAppSelector(state => state.authentication.account?.authorities);
  const updateSuccess = useAppSelector(state => state.clinic.updateSuccess);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(clinicId));
    }

    dispatch(getProfessionals({}));
  }, [clinicId, isNew, dispatch]);

  const saveEntity = values => {
    const entity = {
      ...clinicEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => (isNew ? {} : { ...clinicEntity });

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('photoFile', file);
      dispatch(uploadClinicPhoto({ clinicId, entity: formData }));
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dfaiApp.clinic.home.createOrEditLabel" data-cy="ClinicCreateUpdateHeading">
            <Translate contentKey="dfaiApp.clinic.home.createOrEditLabel">Create or edit a Clinic</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew && (
                <Form>
                  <FormGroup>
                    <Label for="clinic-photoFile">{translate('dfaiApp.clinic.photo')}</Label>
                    <Input
                      id="clinic-photoFile"
                      name="photoFile"
                      accept="image/*"
                      data-cy="photoFile"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </FormGroup>
                </Form>
              )}
              <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                {!isNew ? (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="clinic-id"
                    label={translate('dfaiApp.clinic.id')}
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField
                  label={translate('dfaiApp.clinic.name')}
                  id="clinic-name"
                  name="name"
                  data-cy="name"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField
                  label={translate('dfaiApp.clinic.address')}
                  id="clinic-address"
                  name="address"
                  data-cy="address"
                  type="text"
                />
                <ValidatedField
                  label={translate('dfaiApp.clinic.postalCode')}
                  id="clinic-postalCode"
                  name="postalCode"
                  data-cy="postalCode"
                  type="text"
                />
                <ValidatedField
                  label={translate('dfaiApp.clinic.city')}
                  id="clinic-city"
                  name="city"
                  data-cy="city"
                  type="text"
                />
                <ValidatedField
                  label={translate('dfaiApp.clinic.country')}
                  id="clinic-country"
                  name="country"
                  data-cy="country"
                  type="text"
                />
                <ValidatedField label={translate('dfaiApp.clinic.phone')} id="clinic-phone" name="phone" data-cy="phone" type="text" />
                <ValidatedField label={translate('dfaiApp.clinic.email')} id="clinic-email" name="email" data-cy="email" type="text" />
                <ValidatedField label={translate('dfaiApp.clinic.siret')} id="clinic-siret" name="siret" data-cy="siret" type="text" />
                <ValidatedField
                  label={translate('dfaiApp.clinic.isActive')}
                  id="clinic-isActive"
                  name="isActive"
                  data-cy="isActive"
                  check
                  hidden={!hasAnyAuthority(authorities, [AUTHORITIES.ADMIN])}
                  type="checkbox"
                />
                <ValidatedField label={translate('dfaiApp.clinic.licences')} id="clinic-licences" name="licences" data-cy="licences" type="number" hidden={!hasAnyAuthority(authorities, [AUTHORITIES.ADMIN])}/>

                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/clinic" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ClinicUpdate;
