import ImageViewer from 'app/entities/patient/consultation/image/image-viewer';
import { IImage } from 'app/shared/model/image.model';
import React from 'react';
import { Col } from 'reactstrap';
import SimpleImageViewer from './SimpleImageViewer';

const ImageCollectionRenderer = ({
  title,
  images,
  height = '15vh',
  minHeight = '100px',
  singleLine = false,
  withPredictionOnly = false,
  hidePrediction = false,
  setDetailedImage,
  setHideLegendsInModal = null,
  imageContainers,
  patientId,
  testBlack = false,
  simpleImages = false,
}) => {
  // Filtrer les images en fonction de withPredictionOnly
  const filteredImages = images.filter(image => {
    if (withPredictionOnly) {
      return image.pathologyPredictions && image.pathologyPredictions.length > 0;
    }
    return true;
  });

  // Si withPredictionOnly est vrai et qu'il n'y a pas d'images filtrées, retourner null
  if (withPredictionOnly && filteredImages.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <h3 className="text-lg font-bold mb-2">{title}</h3>
      <div className={`grid ${singleLine ? 'grid-cols-1' : 'grid-cols-2 md:grid-cols-4 lg:grid-cols-8'} gap-4-tailwind mb-4`}>
        {filteredImages.map((image, index) => {
          const typedImage = image as IImage;
          return (
            <>
              {typedImage && (
                <div
                  className=" h-full relative w-full pb-full overflow-hidden"
                  ref={imageContainers.current.get(typedImage.id)}
                  onClick={() => {
                    setDetailedImage(typedImage);
                    if (setHideLegendsInModal) setHideLegendsInModal(hidePrediction);
                  }}
                  style={{ paddingBottom: singleLine ? '50%' : '100%' }}
                >
                  {simpleImages ? (
                    <SimpleImageViewer
                      className="absolute inset-0 object-cover w-full h-full"
                      imageEntity={typedImage}
                      patientId={patientId}
                      testBlack={false}
                    />
                  ) : (
                    <ImageViewer
                      imageEntity={typedImage}
                      pathologyPredictions={hidePrediction ? [] : typedImage.pathologyPredictions}
                      patientId={patientId}
                      containerRef={imageContainers.current.get(typedImage.id)}
                      patientContext={true}
                    />
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ImageCollectionRenderer;
