import { Link } from 'react-router-dom';
import React from 'react';

const PatientHeader = () => {
  return (
    <React.Fragment>
      <Link to="/patient-app" className="flex items-center justify-center footer-patient-tailwind">
        <img src="/content/images/logo.png" alt="Dragonfly AI Medical" className="h-10 w-auto mr-2" />
      </Link>
      <nav className="ml-auto flex gap-4 sm:gap-6 items-center">
        <Link to="/patient-app" className="text-lg font-light hover:underline underline-offset-4 footer-patient-tailwind">
        Rendez-vous
        </Link>
        <Link to="/logout" className="footer-patient-tailwind">
          <button className="text-lg font-light hover:underline underline-offset-4 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
              />
            </svg>
          </button>
        </Link>
      </nav>
    </React.Fragment>
  );
};

export default PatientHeader;
