import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IProfessional, defaultValue } from 'app/shared/model/professional.model';

const initialState: EntityState<IProfessional> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  overview: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/professionals';
const practitionersUrl = 'api/practitioners';
const clinicApiUrl = 'api/clinics'

// Actions

export const getEntities = createAsyncThunk('professional/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IProfessional[]>(requestUrl);
});

export const getPractitioners = createAsyncThunk('professional/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${practitionersUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IProfessional[]>(requestUrl);
});

export const getClinicProfessionals = createAsyncThunk('clinic/professional/fetch_entity_list', async ({ id, page, size, sort }: IQueryParams) => {
  const requestUrl = `${clinicApiUrl}/${id}/professionals?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IProfessional[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'professional/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IProfessional>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'professional/create_entity',
  async (entity: IProfessional, thunkAPI) => {
    const result = await axios.post<IProfessional>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'professional/update_entity',
  async (entity: IProfessional, thunkAPI) => {
    const result = await axios.put<IProfessional>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'professional/partial_update_entity',
  async (entity: IProfessional, thunkAPI) => {
    const result = await axios.patch<IProfessional>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'professional/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IProfessional>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const ProfessionalSlice = createEntitySlice({
  name: 'professional',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getClinicProfessionals), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ProfessionalSlice.actions;

// Reducer
export default ProfessionalSlice.reducer;
