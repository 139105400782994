import React, { useState, useEffect, useRef, createRef } from 'react';
import { getLoggedPatientInfo } from 'app/shared/reducers/authentication';

import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity as getConsultation, getEntityReport } from 'app/entities/patient/consultation/consultation.reducer';
import { getConsultationImages } from 'app/entities/patient/consultation/image/image.reducer';
import { predict } from 'app/entities/patient/consultation/prediction.reducer';
import { getConsultationEntities as getQuotes } from 'app/entities/patient/consultation/quotes/quote.reducer';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/Card';
import PatientModalWithLegend from '../components/PatientModalWithLegend';
import ImageCollectionRenderer from '../components/ImageCollectionRenderer';
import TextToSpeech from '../components/TextToSpeech';
import { FileIcon, FileTextIcon } from '../components/icons';
import { translate } from 'react-jhipster';



const AppointmentDetail: React.FC = () => {
  const loggedPatientInfo = useAppSelector(getLoggedPatientInfo);
  const dispatch = useAppDispatch();
  const { patientId, id: consultationId } = useParams<{ patientId: string; id: string }>(); // Ensure the parameter names match the route
  // Utilisez patientId et consultationId pour récupérer les détails nécessaires
  console.log('Patient ID:', patientId);
  console.log('Consultation ID:', consultationId);

  const [detailedImage, setDetailedImage] = useState(null);
  const [panoramics, setPanoramics] = useState([]);
  const [xRays, setXRays] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [mailText, setMailText] = useState(null);
  const [hideLegendsInModal, setHideLegendsInModal] = useState(false);
  const imageContainers = useRef(new Map());

  const quotes = useAppSelector(state => state.quote.entities);
  const consultation = useAppSelector(state => state.consultation.entity);

  useEffect(() => {
    if (patientId && consultationId) {
      // Charger toutes les données nécessaires en une seule fois
      dispatch(getConsultation({ patientId, consultationId }));
      dispatch(getEntityReport({ patientId, consultationId, locale: 'FR' }));
      dispatch(getConsultationImages({ patientId, consultationId }));
      dispatch(getQuotes({ patientId, consultationId }));
      dispatch(predict({ patientId, consultationId }));
    }
  }, [patientId, consultationId, dispatch]);

  const recommendationsText = consultation?.report?.recommendations
    ? `${translate('dfaiApp.consultation.report.mailPrefix')}\n${consultation.report.recommendations
        .map(rec => rec.replaceAll('\\n', '\n'))
        .join('\n\n')}\n${translate('dfaiApp.consultation.report.mailSuffix')}`
    : '';

  useEffect(() => {
    if (consultation?.report?.mail) {
      setMailText(consultation.report.mail);
    }
  }, [consultation]);

  useEffect(() => {
    if (consultation) {
      const images = consultation.images || [];
      const newXRays = [];
      const newPhotos = [];
      const newPanoramics = [];

      images.forEach(image => {
        if (image.type === 'panoramic') {
          newPanoramics.push(image);
          imageContainers.current.set(image.id, createRef());
        } else if (image.type === 'xray') {
          newXRays.push(image);
          imageContainers.current.set(image.id, createRef());
        } else if (image.type === 'photo') {
          newPhotos.push(image);
          imageContainers.current.set(image.id, createRef());
        }
      });

      setXRays(newXRays);
      setPhotos(newPhotos);
      setPanoramics(newPanoramics);
    }
  }, [consultation]);

  const handleViewQuote = quote => {
    if (patientId && consultationId) {
      window.open(`api/patients/${patientId}/consultations/${consultationId}/quotes/${quote.id}/file`, '_blank');
    } else {
      console.error("Patient ID or Consultation ID is missing");
    }
  };

  return (
    <div className="container mx-auto py-12 px-4">
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 bg-muted flex flex-col items-center">
          <div className="container space-y-12 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl mb-6">Détails du Rendez-vous</h2>

            {(panoramics.length || xRays.length || photos.length) && (
              <Card>
                <CardHeader>
                  <CardTitle>Prédictions</CardTitle>
                </CardHeader>
                <CardContent>
                  {panoramics.length > 0 && (
                    <ImageCollectionRenderer title="Panoramique" images={panoramics} height="35vh" minHeight="100px" singleLine setDetailedImage={setDetailedImage} setHideLegendsInModal={setHideLegendsInModal} imageContainers={imageContainers} patientId={patientId} />
                  )}
                  { xRays.length > 0 && (
                      <ImageCollectionRenderer 
                          title="Radios" 
                          images={xRays} 
                          hidePrediction={false}  // Ajouté pour uniformité
                          height="15vh" 
                          minHeight="100px" 
                          setDetailedImage={setDetailedImage} 
                          setHideLegendsInModal={setHideLegendsInModal} 
                          imageContainers={imageContainers} 
                          patientId={patientId} 
                      />
                  )}
                  { photos.length > 0 && (
                      <ImageCollectionRenderer 
                          title="Photos" 
                          images={photos} 
                          hidePrediction={false}  // Ajouté pour uniformité
                          height="10vh" 
                          minHeight="75px" 
                          setDetailedImage={setDetailedImage} 
                          setHideLegendsInModal={setHideLegendsInModal} 
                          imageContainers={imageContainers} 
                          patientId={patientId} 
                      />
                  )}
                </CardContent>
              </Card>
            )}

        <div className="space-y-8">
          <Card>
            <CardHeader>
              <CardTitle>Devis</CardTitle>
            </CardHeader>
            <CardContent>
              {quotes.map((quote, index) => (
                <div key={index} className="flex items-center gap-2">
                  <FileTextIcon className="h-8 w-8 text-muted-foreground" />
                  <span>{quote.originalName || quote.sourceFileName}</span>
                  <FileIcon onClick={() => handleViewQuote(quote)} className="cursor-pointer h-8 w-8 text-muted-foreground ml-auto" />
                </div>
              ))}
            </CardContent>
          </Card>
        </div>


            <Card>
              <CardHeader>
                <CardTitle>Courrier de votre praticien</CardTitle>
              </CardHeader>
              <CardContent>
                {mailText ? (
                  <>
                    <p className="text-muted-foreground">{mailText}</p>
                    <TextToSpeech text={mailText} />
                  </>
                ) : recommendationsText ? (
                  <>
                    <p className="text-muted-foreground">{recommendationsText}</p>
                    <TextToSpeech text={recommendationsText} />
                  </>
                ) : (
                  <p className="text-muted-foreground">Aucun courrier disponible.</p>
                )}
              </CardContent>
            </Card>
            {(panoramics && panoramics.length > 0) || (xRays && xRays.length > 0) || (photos && photos.length > 0) ? (
              <Card>
                <CardHeader>
                  <CardTitle>Photos</CardTitle>
                </CardHeader>
                <CardContent>
                  {panoramics && panoramics.length > 0 && (
                    <ImageCollectionRenderer
                      title="Panoramique"
                      images={panoramics}
                      hidePrediction={true}
                      height="35vh"
                      minHeight="100px"
                      singleLine={true}
                      setDetailedImage={setDetailedImage}
                      setHideLegendsInModal={setHideLegendsInModal}
                      imageContainers={imageContainers}
                      patientId={patientId}
                    />
                  )}

                  {xRays && xRays.length > 0 && (
                    <ImageCollectionRenderer
                      title="Radios"
                      images={xRays}
                      hidePrediction={true}
                      height="15vh"
                      minHeight="100px"
                      setDetailedImage={setDetailedImage}
                      setHideLegendsInModal={setHideLegendsInModal}
                      imageContainers={imageContainers}
                      patientId={patientId}
                    />
                  )}

                  {photos && photos.length > 0 && (
                    <ImageCollectionRenderer
                      title="Photos"
                      images={photos}
                      hidePrediction={true}
                      height="10vh"
                      minHeight="75px"
                      setDetailedImage={setDetailedImage}
                      setHideLegendsInModal={setHideLegendsInModal}
                      imageContainers={imageContainers}
                      patientId={patientId}
                    />
                  )}
                </CardContent>
              </Card>
           ) : null}
          </div>
          {detailedImage && (
            <PatientModalWithLegend selectedImageEntity={detailedImage} closePredictionModal={() => setDetailedImage(null)} patientId={patientId} simpleImages={hideLegendsInModal} legends={!hideLegendsInModal} />
          )}
        </section>
      </main>
    </div>
  );
};

export default AppointmentDetail;
