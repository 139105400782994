import axios from 'axios';
import { Storage } from 'react-jhipster';
import { REDIRECT_URL } from 'app/shared/util/url-utils';
import { useLocation } from 'react-router';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    // Retrieve the locale from session storage or set default to 'fr'
    const locale = Storage.session.get('locale') || 'fr';
    
    // Add Accept-Language header to the request config
    config.headers['Accept-Language'] = locale;
    
    return config;
  };

  const onResponseSuccess = response => response;

  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      const pageLocation = useLocation();
      localStorage.setItem(REDIRECT_URL, pageLocation.state.from.pathname);
      window.location.reload();
    }
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
