import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './clinic.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { isMobile } from 'react-device-detect';

export const Clinic = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const clinicList = useAppSelector(state => state.clinic.entities);
  const authorities = useAppSelector(state => state.authentication.account?.authorities);
  const loading = useAppSelector(state => state.clinic.loading);
  const totalItems = useAppSelector(state => state.clinic.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="clinic-heading" data-cy="ClinicHeading">
        <Translate contentKey="dfaiApp.clinic.home.title">Clinics</Translate>
        <div className="d-flex justify-content-end">
          { hasAnyAuthority(authorities, [AUTHORITIES.CREATE_CLINIC]) &&
            <Link to="/clinic/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="dfaiApp.clinic.home.createLabel">Create new Clinic</Translate>
            </Link>
          }
        </div>
      </h2>
      <div className="table-responsive">
        {clinicList && clinicList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="dfaiApp.clinic.name">Name</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
                </th>
                {!isMobile && <th className="hand" onClick={sort('address')}>
                  <Translate contentKey="dfaiApp.clinic.address">Address</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('address')} />
                </th>}
                {!isMobile && <th className="hand" onClick={sort('phone')}>
                  <Translate contentKey="dfaiApp.clinic.phone">Phone</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('phone')} />
                </th>}
                {!isMobile && <th className="hand" onClick={sort('email')}>
                  <Translate contentKey="dfaiApp.clinic.email">Email</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('email')} />
                </th>}
                {!isMobile && <th className="hand" onClick={sort('siret')}>
                  <Translate contentKey="dfaiApp.clinic.siret">Siret</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('siret')} />
                </th>}
                { hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_CLINIC]) && !isMobile &&
                  <th className="hand" onClick={sort('isActive')}>
                    <Translate contentKey="dfaiApp.clinic.isActive">Is Active</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('isActive')} />
                  </th>
                }
                {!isMobile && <th className="hand" onClick={sort('licences')}>
                  <Translate contentKey='dfaiApp.clinic.licences'>Licence count</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('licences')} />
                </th>}
                {!isMobile && <th />}
              </tr>
            </thead>
            <tbody>
              {clinicList.map((clinic, i) => (
                <tr key={`entity-${i}`} className="clickable-row" data-cy="entityTable" >
                  <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.name}</td>
                  {!isMobile && <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.address}</td>}
                  {!isMobile && <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.phone}</td>}
                  {!isMobile && <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.email}</td>}
                  {!isMobile && <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.siret}</td>}
                  {!isMobile && <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.licences}</td>}
                  { hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_CLINIC]) && !isMobile && 
                    <td style={{ verticalAlign: 'middle' }} onClick={() => navigate(`/clinic/${clinic.id}`)}>{clinic.isActive ? 'true' : 'false'}</td>
                  }
                  {!isMobile && <td className="text-end">
                    <div>
                      { hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_CLINIC]) &&
                        <Button
                          tag={Link}
                          to={`/clinic/${clinic.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="info"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      }
                      { hasAnyAuthority(authorities, [AUTHORITIES.DELETE_CLINIC]) &&
                        <Button
                          onClick={() =>
                            (window.location.href = `/clinic/${clinic.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                          }
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      }
                    </div>
                  </td>}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dfaiApp.clinic.home.notFound">No Clinics found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={clinicList && clinicList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Clinic;
