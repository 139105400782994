// patient-app/routes.tsx
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PatientSelectionPage from './pages/PatientSelectionPage';
import PatientConsultationsPage from './pages/PatientConsultationsPage';
import AppointmentDetail from './pages/AppointmentDetail';
import LegalInformation from './pages/LegalInformation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import PatientReport from 'app/entities/patient/consultation/patient-report';




const PatientUserRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PatientSelectionPage />} />
      <Route path=":socialNumber/consultations" element={<PatientConsultationsPage />} />
      <Route path="appointments/:patientId/:id" element={<AppointmentDetail />} />
      <Route path="report/:consultationId" element={<PatientReport patientContext />} />
      <Route path="/legal" element={<LegalInformation />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />      
      {/* Redirection par défaut vers PatientSelectionPage */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default PatientUserRoutes;
