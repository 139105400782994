import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { faTimesCircle, faCamera, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'app/config/store';
import { getConsultationImage } from './consultation.reducer';
import { useNavigate } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import { base64ToBlob, getImageFromCache, setImageInCache } from 'app/shared/util/cache-utils';
// Import Bootstrap Spinner
import { Spinner } from 'reactstrap';

function ConsultationImageInput({ id, section, type, consultationId, patientId, image, uploadCallback = () => {}, canDelete }) {
  const [currentImage, setCurrentImage] = useState(image);
  const [imageUrl, setImageUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      handleFileChange(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: imageUrl ? true : false });

  useEffect(() => {
    const fetchImage = async () => {
      if (currentImage?.storedName) {
        const cacheKey = `consultation-${image.id}`;

        // Check cache first
        const cachedBlob = getImageFromCache(cacheKey);
        if (cachedBlob) {
          const objectURL = URL.createObjectURL(cachedBlob);
          setImageUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL); // Clean up Blob URL
        }

        // Fetch image and store in cache
        try {
          const response = await dispatch(getConsultationImage({ patientId, image: currentImage })).unwrap();
          const objectURL = URL.createObjectURL(response);
          setImageUrl(objectURL);
          await setImageInCache(cacheKey, response); // Save the Blob as Base64
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load image:', error);
        }
      } else {
        console.log('no image');
      }
    };

    fetchImage();
  }, [currentImage, dispatch, patientId, consultationId]);

  useEffect(() => {
    setCurrentImage(image);
  }, [image]);

  const navigateToImageDetails = () => {
    navigate(`/patient/${patientId}/consultation/${consultationId}/image/${currentImage.id}/edit`);
  };

  const handleFileChange = async (file) => {
    if (!file) return;

    setUploading(true);
    try {
      const formData = new FormData();
      const imageDetails = {
        section: section,
        type: type,
        consultation: {
          id: consultationId,
        },
      };
      formData.append('file', file);
      formData.append('imageDetails', new Blob([JSON.stringify(imageDetails)], { type: 'application/json' }));

      const response = await axios.post(`api/patients/${patientId}/consultations/${consultationId}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setCurrentImage(response.data);
      uploadCallback();
    } catch (error) {
      toast.error(translate(error.response.data.message));
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!currentImage) return;
    try {
      await axios.delete(`api/patients/${patientId}/consultations/${consultationId}/images/${currentImage.id}`);
      setCurrentImage(null);
      setImageUrl(''); // Clear the image URL when deleted
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div className="d-flex flex-wrap relative" {...getRootProps()}>
      <form className="container-fluid p-0">
        <div
          className="d-flex flex-wrap align-items-center justify-content-center"
          style={{ cursor: 'pointer' }}
          onClick={imageUrl ? navigateToImageDetails : () => {}}
        >
          {uploading ? (
            // Spinner during upload
            <div style={{ height: '12rem', width: '12rem' }} className="bg-light d-flex align-items-center justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : imageUrl ? (
            <div className="position-relative">
              <img className="img-fluid" src={imageUrl} alt="Uploaded" style={{ maxHeight: '12rem', maxWidth: '100%' }} />
              {currentImage?.validated && (
                <div className="position-absolute top-0 start-0 w-100 text-green d-flex justify-content-end">
                  <FontAwesomeIcon icon={faCircleCheck} type="outline" height="3rem" fill="currentColor" />
                </div>
              )}
              {canDelete && (
                <button onClick={(event) => handleDelete(event)} className="btn btn-danger position-absolute top-0 end-0 m-1 rounded-circle">
                  <FontAwesomeIcon icon={faTimesCircle} className="text-white" />
                </button>
              )}
            </div>
          ) : (
            <div style={{ height: '12rem', width: '12rem' }} className="bg-light d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faCamera} className="text-secondary fs-1" />
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            className="d-none"
            onChange={(event) => handleFileChange(event.target.files[0])}
            disabled={uploading}
            {...getInputProps()}
          />
        </div>
      </form>
    </div>
  );
}

export default ConsultationImageInput;
